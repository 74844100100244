@Font-face {
    font-family: 'Inter';
    src: url('fonts/static/Inter-Light.ttf'); /* IE9 Compat Modes */
  }
  @Font-face {
    font-family: 'Inter-SemiBold';
    src: url('fonts/static/Inter-SemiBold.ttf'); /* IE9 Compat Modes */
  }
  @Font-face {
    font-family: 'Inter-Regular';
    src: url('fonts/static/Inter-Regular.ttf'); /* IE9 Compat Modes */
  }
   
  @Font-face {
    font-family: 'Inter-Medium';
    src: url('fonts/static/Inter-Medium.ttf'); /* IE9 Compat Modes */
  }
  
  